import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import ContentBlock from 'src/components/contentBlock';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "toggle"
    }}>{`Toggle`}</h1>
    <ContentBlock imgName="Form-toggle.png" mdxType="ContentBlock">
  <code className="clike">
    {`
/* 
  struct Toggle<Label> where Label : View
*/
struct FormToggle: View {
    @State private var areTogglesOn: Bool = false\n
    var body: some View {
        Form {
            Toggle("Toggle with just text", isOn: $areTogglesOn)\n
            Toggle(isOn: $areTogglesOn) {
                HStack {
                    Text("Toggle with icon")
                    Image(systemName: "pencil.circle.fill")
                }
            }\n
        }
    }
}
    `}
  </code>
    </ContentBlock>
    <h2 {...{
      "id": "plain-text-label"
    }}>{`Plain Text Label`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-clike"
      }}>{`  Toggle("Toggle with just text", isOn: $areTogglesOn)
`}</code></pre>
    <h2 {...{
      "id": "custom-label"
    }}>{`Custom Label`}</h2>
    <p>{`Create a custom label by passing a view as a callback. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-clike"
      }}>{`Toggle(isOn: $areTogglesOn) {
    HStack {
        Text("Toggle with icon")
        Image(systemName: "pencil.circle.fill")
    }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      